import { format, utcToZonedTime } from 'date-fns-tz';
import { last } from 'lodash';

export const formatLargeAmount = (amount: number) => {
  const absoluteValue = Math.abs(amount);
  const THOUSAND_UNIT = 1_000;
  const MILLION_UNIT = 1_000_000;
  const THOUSAND_SUFFIX = 'k';
  const MILLION_SUFFIX = 'mil';

  if (absoluteValue >= MILLION_UNIT) {
    return `${parseFloat((absoluteValue / MILLION_UNIT).toFixed(2))}${MILLION_SUFFIX}`;
  }

  if (absoluteValue >= THOUSAND_UNIT) {
    return `${parseFloat((absoluteValue / THOUSAND_UNIT).toFixed(2))}${THOUSAND_SUFFIX}`;
  }

  return `${parseFloat(amount.toFixed(1))}`;
};

export function pluralizeWord(word: string, count: number) {
  const pluralRules = new Intl.PluralRules('en-US');
  const pluralForm = pluralRules.select(count);
  const wordEndings = ['ch', 'sh', 'x', 's', 'z', 'o', 'y', 'f', 'fe'];
  const hasEnding = wordEndings.some((ending) => word.endsWith(ending));

  switch (pluralForm) {
    case 'one':
      return `${word}`;

    default:
      if (hasEnding) {
        return `${word}es`;
      } else {
        return `${word}s`;
      }
  }
}

export const formatSummaryTokenAmount = (amount = 0) => {
  // quick solution to prevent rounding and handle numbers like 8.1244e-7
  // because 0.999999.toFixed(4) will return 1.0000
  // and we need 0.999999 -> 0.9999
  // not bulletproof because 0.99999999999999999 will still round it to 1
  // TODO: update formatTokenReward
  return Number(amount.toFixed(20).replace(/(\.\d{4})\d+/, '$1'));
};

export const formatMembershipActionDate = (date: string) =>
  `${format(utcToZonedTime(date, 'UTC'), 'dd/MM/yyyy HH:mm', { timeZone: 'UTC' })} UTC`;

export const formatNumberWithNSign = (value: number | string, sign = 2) => {
  if (!value) {
    return 0;
  }

  return Number(value).toFixed(sign);
};

export const truncateDecimals = (amount: string | number, decimals: number): string => {
  return amount ? `${amount}`.replace(new RegExp(`(\\.\\d{${decimals}})\\d+`), '$1') : '0';
};

// Formats a number into a locale-specific string representation.
// Input: amount (number) to format, and an optional locale code (string) with a default value of 'en-US'.
// Result: A string representing the formatted number according to the specified locale.
// Example: formatNumberLocalized(1234567, 'en-US') => "1,234,567"
export const formatNumberLocalized = (
  amount?: number,
  { locale = 'en-US', options }: { locale?: string; options?: Intl.NumberFormatOptions } = {},
) => {
  if (typeof amount !== 'number') {
    return '';
  }

  return new Intl.NumberFormat(locale, {
    //eslint-disable-next-line
    //@ts-ignore
    roundingMode: 'trunc',
    maximumFractionDigits: 2,
    ...options,
  }).format(amount);
};

// Formats a number into a currency-specific string using a locale and currency code.
// Input: amount (number) to format, locale code (string) with a default value of 'en-US',
// and currency code (string) with a default value of 'USD'.
// Result: A string representing the number formatted as currency according to the specified locale and currency.
// Example: formatNumberAsCurrency(1234567, 'en-US', 'USD') => "$1,234,567.00"
export const formatNumberAsCurrency = ({
  amount,
  locale = 'en-US',
  currency = 'USD',
  minimumFractionDigits = 0,
  maximumFractionDigits,
}: {
  amount: number;
  locale?: string;
  currency?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    // eslint-disable-next-line
    //@ts-ignore
    roundingMode: 'trunc',
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(amount);
};

export const getFormatNumber = (amount = 0, decimals = 2) => {
  const strAmount = amount.toString();
  const [integerPart, decimalPart = ''] = strAmount.split('.');

  // Ограничиваем дробную часть двумя знаками
  const formattedDecimalPart = decimalPart.slice(0, decimals).padEnd(decimals, '0');

  return formattedDecimalPart ? `${integerPart}.${formattedDecimalPart}` : integerPart;
};

export const formatList = (
  items: Array<string>,
  separator = ', ',
  operator?: 'and' | 'or',
) => {
  switch (items.length) {
    case 0:
      return '';

    case 1:
      return items[0];

    case 2:
      return items[0] + ' ' + operator + ' ' + items[1];

    default:
      return (
        items.slice(0, items.length - 1).join(separator) +
        separator +
        (operator ? operator + ' ' : '') +
        last(items)
      );
  }
};
